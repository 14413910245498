<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                机器学习如何助力量化投资？非凸联合创始人李佐凡为你揭秘
              </p>
              <div class="fst-italic mb-2">2022年9月8日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >直播宣讲</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/13/01-封面.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    相比传统量化策略，机器学习能克服人们的认知局限，在多维度、大样本的海量数据中发现高纬度、非线性的潜在复杂关系。随着数据和算力的爆发式增长，机器学习技术在量化投资领域的应用越来越深入。
                  </p>
                   <p>
                    9月8日，非凸科技联合创始人&CTO李佐凡受邀参加复旦大学管理学院职业发展中心组织的职业课堂活动，为同学们揭秘“机器学习如何应用于量化投资”。从“量化投资是什么”到“机器学习方法”，再到“高频交易应用”，形象举例，娓娓道来，让人听了饶有兴趣。
                  </p>
                  <h4 class="my-4">一、什么是量化投资？</h4>
                  <p>
                    简单来说，量化投资的核心是数字分析、规律总结，然后用统计的方法，找到投资的理念或投资的策略。
                  </p>
                  <p>
                    2013年的一篇论文“Buffet’s Alpha”，讲述了巴菲特的“价值投资”是一种什么样的方法，即他是如何从“Betting-Against-Beta”、“Quality-Minus-Junk”、“Leverage”三种方法中获取超额回报。那么，如果没有量化投资的方法，我们该如何进行投资？
                  </p>
                  <p>
                    紧接着，李佐凡通过一组投资组合的对比曲线来说明：如果用量化的方法来帮助巴菲特进行价值投资，那么有可能做的比他自己做的更好。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/13/02-曲线图.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <h4 class="my-4">二、量化投资与机器学习</h4>
                  <p>
                    近年来，机器学习应用场景越来越多，比如人脸识别、自动驾驶等，这些都是对历史数据的归纳总结，以及对未来的延展。
                  </p>
                  <p>
                    2020年的一篇论文“(Re-)Imag(in)ing Price Trends”，讲述了如何通过机器学习的方法去预测股票价格的未来走势。其中，李佐凡举例了常见的机器学习方法“CNN”（卷积神经网络）。图中可以看出，从5天Image到20天Image再到60天Image，顶部图像越来越大，中间层数越来越多，最后可以看到一个很大的神经网络，节点数有18万。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/13/03-Image.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    有时我们会发现，机器学习的数据集常常不够用，能使用的历史数据很少，新问题很难解决。那常见的方法是，在其他的数据集上训练神经网络，然后运用到要解决的新问题上，即另一种机器学习方法“Transfer learning”。比如，用美国的股票数据来训练一个模型，然后应用到其他国家股票上，最后得到的回报结果很好。
                  </p>
                  <h4 class="my-4">三、机器学习在高频交易中的应用</h4>
                   <p>
                    超高频的投资，大多数买卖行为发生在毫秒之间，1毫秒内可能有多次换手，于是会引发与对手博弈的过程。李佐凡表示，做投资决策时，不仅要对市场进行预测，还要对投资对手进行预测。
                  </p>
                   <p>
                    比如常见的德州扑克，体现了投资中的风险和收益，也很好地阐释了人们从0到1学习的一种算法。还比如，2016年AlphaGo打败了Lee Sedol，2019年AlphaStar打败了LiquidTLO，2019年OpenAI Five 打败了OG等，都说明了机器学习方法可以得到更好的表现效果。
                  </p>
                   <p>
                    最后，李佐凡针对同学们的提问，比如职业选择、量化行业看法、策略有效性、量化学习建议等方面，一一做出了解答。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/13/04-QA.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    量化是在不确定的市场中寻找相对确定的科学逻辑。非凸科技作为一家使用全栈Rust的金融科技公司，凭借先进的策略、稳定的系统和完善的服务，以及热忱的技术信仰、执着的策略迭代，获得了业界一致好评。
                  </p>
                   <p>
                    未来，我们仍将持共同进步的态度，吸取建议，不断提升，为更多的量化机构提供优质的算法服务。也欢迎热爱量化、技术的小伙伴，加入非凸！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News13",
};
</script>

<style></style>
